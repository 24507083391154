<template>
    <div class="snake-loader">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
    </div>
</template>

<script>
export default {
    name: 'SnakeLoader',
}
</script>

<style lang="scss">
@import './SnakeLoader.scss';
</style>
