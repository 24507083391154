export default {
    methods: {
        convertToIndianNumberSystem(value){
            value = value.toString();
            let number = value.split(".");
            value = number[0];
          
            var lastThree = value.substring(value.length - 3);
            var otherNumbers = value.substring(0, value.length - 3);
            if (otherNumbers != "") lastThree = "," + lastThree;
            return (
              otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
              lastThree +
              (number.length > 1 ? "." + number[1] : "")
            );
        }
    }
  }