<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div
            class="scene-details-page"
            v-if="Object.keys(sceneDetails).length > 0"
        >
            <div class="scene-image-wrapper">
                <img
                    :src="
                        sceneDetails.scene_data.scene_image_data_list[0]
                            .scene_image_url.sc_image
                    "
                    :alt="sceneDetails.scene_data.room_type"
                />
                <div class="botton-corner-button">
                    <Button
                        v-if="sceneDetails.vt_url"
                        primary
                        name="Virtual Tour"
                        class="virtual-tour-button"
                        :handleClick="openVT"
                    >
                        <template #icon>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19.767"
                                height="19.767"
                                class="virtual-tour-icon"
                            >
                                <g
                                    id="play-button"
                                    transform="translate(-11.85 .15)"
                                >
                                    <g
                                        id="Group_178"
                                        data-name="Group 178"
                                        transform="translate(12)"
                                    >
                                        <g id="Group_177" data-name="Group 177">
                                            <path
                                                id="Path_559"
                                                data-name="Path 559"
                                                class="cls-1"
                                                d="M9.733 0a9.733 9.733 0 1 0 9.733 9.733A9.744 9.744 0 0 0 9.733 0zm0 18.656a8.922 8.922 0 1 1 8.922-8.922 8.932 8.932 0 0 1-8.922 8.922z"
                                                fill="#fff"
                                                stroke="#fff"
                                                stroke-width="0.3px"
                                            />
                                            <path
                                                id="Path_560"
                                                data-name="Path 560"
                                                class="cls-1"
                                                d="m198.3 153.046-5.678-3.65a.406.406 0 0 0-.625.341v7.3a.405.405 0 0 0 .625.341l5.678-3.65a.405.405 0 0 0 0-.682zm-5.492 3.248v-5.814l4.522 2.907z"
                                                transform="translate(-184.7 -143.653)"
                                                fill="#fff"
                                                stroke="#fff"
                                                stroke-width="0.3px"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </template>
                    </Button>
                    <!-- <Button
                    v-if="sceneDetails.scene_data.is_3d_available == true"
                    name="Customise"
                    primary
                    class="customisation-button"
                    :data-scene-id="sceneDetails.scene_data.id"
                    @click.native="this.$router.push({ path:'/' })"
                /> -->
                </div>
            </div>
            <div class="content-container">
                <div class="scene-details">
                    <div class="scene-info">
                        <div>
                            <h1 class="room-type">
                                {{ sceneDetails.scene_data.room_type }}
                            </h1>
                            <span class="vertical-line"></span>
                            <h1
                                class="scene-price"
                                :class="requiredDomain == false ? 'blur' : ''"
                            >
                                ₹
                                {{
                                    convertToIndianNumberSystem(
                                        sceneDetails.scene_data.price,
                                    )
                                }}
                            </h1>
                        </div>
                        <p class="style-name">
                            Style:
                            <span>{{ sceneDetails.theme_data.name }}</span>
                        </p>
                        <p class="description">
                            {{ sceneDetails.scene_data.description }}
                        </p>
                    </div>
                    <div class="scene-designer">
                        <div class="designer">
                            <CircularImage
                                :img="
                                    sceneDetails.scene_data.designer_image
                                        .s_image
                                "
                            />
                        </div>
                        <p>
                            Designed by
                            <span class="designer-name">{{
                                sceneDetails.scene_data.designer_name
                            }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <!-- <div class="start-project"> -->
            <!-- <div>
                <h1>
                    Ready to design your room?
                </h1>
                <Button primary name="Start your Project" />
            </div> -->
            <!-- </div> -->
            <div class="content-container">
                <div class="scene-products">
                    <div class="header">
                        <h1>
                            {{ sceneDetails.theme_data.name }}
                            {{ sceneDetails.scene_data.room_type }} Products
                        </h1>
                        <!-- <p>Buy all Products @1,55,000</p> -->
                    </div>
                    <div class="products">
                        <div
                            v-for="product in sceneDetails.scene_data
                                .furnishing_products_in_scene"
                            :key="product.id"
                        >
                            <router-link
                                :to="{
                                    name: 'ProductDetailsPage',
                                    params: { id: product.product_id },
                                }"
                            >
                                <Cards
                                    :img="product.image_meta"
                                    :cardname="product.name"
                                    :requiredDomain="requiredDomain"
                                    :price="
                                        `₹ ${convertToIndianNumberSystem(
                                            product.item_price,
                                        )}`
                                    "
                                />
                            </router-link>
                        </div>
                    </div>
                </div>

                <StyleBox
                    class="style-quiz-banner"
                    rightBanner
                    img="decoration-2373342_1920.png"
                    heading="Unsure About Your Decor Style?"
                    style="margin-top: 100px; margin-bottom: 50px;"
                />
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ConvertToPrice from 'mixins/ConvertToPrice'
import SceneDetailsPageModule from 'store/modules/SceneDetailsPage'
import CircularImage from 'componentsv2/CircularImage'
import Cards from 'componentsv2/Cards'
import Button from 'componentsv2/Button'
import StyleBox from 'componentsv2/StyleBox'
import Footer from 'componentsv2/Footer'
import Loader from 'componentsv2/Loader'
import loaderHandler from 'mixins/loader'

export default {
    name: 'SceneDetailsPage',
    components: {
        Cards,
        Button,
        StyleBox,
        Footer,
        CircularImage,
        Loader,
    },
    mixins: [RegisterStoreModule, ConvertToPrice, loaderHandler],
    computed: {
        ...mapState({
            sceneDetails: state => state.SceneDetailsPage.sceneDetails,
        }),
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'SILOHO',
        // all titles will be injected into this template
        titleTemplate: '%s | Scene details',
    },
    props: {
        requiredDomain: Boolean,
    },
    created() {
        this.registerStoreModule('SceneDetailsPage', SceneDetailsPageModule)
        this.fetchSceneDetails(this.$route.params.id)
            .then(res => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            })
            .catch(err => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            })
    },
    destroyed() {
        this.$store.unregisterModule('SceneDetailsPage')
    },
    methods: {
        ...mapActions({
            fetchSceneDetails: 'fetchSceneDetails',
        }),
        openVT() {
            window.open(this.sceneDetails.vt_url[0])
        },
    },
    // mounted() {
    //     setTimeout(() => {
    //         this.loaderShow = false
    //     }, 1000)
    // },
}
</script>

<style lang="scss" scoped>
@import './SceneDetailsPage.scss';
</style>
