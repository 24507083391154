import Api from 'utils/Api'
import {getCookie} from 'utils/getCookie'

export function getScenesCollection(){
    return Api
        .get('CADVisualization/collection-of-scene/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        }) 
}

export function getSpecialScenes(){
    return Api
        .get('CADVisualization/scene/special_scene/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        }) 
}

export function getScenes({is_filter, theme_id, budget, room_type, designer_id , page}){
    
    let filter_str='';

    filter_str+=`is_filter=${is_filter}`
    
    if(theme_id){
        filter_str+=`&theme_id=${theme_id}`
    }

    if(budget){
        filter_str+=`&budget=${budget}`
    }

    if(room_type){
        filter_str+=`&room_type=${room_type}`
    }

    if(designer_id){
        filter_str+=`&designer_id=${designer_id}`
    }

    if(page){
        filter_str+=`&page=${page}`
    }
    
    return Api
        .get(`CADVisualization/scene/?${filter_str}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
            
}

export function getSceneDetails(id){
    return Api
        .get(`CADVisualization/scene/${id}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getCustomScene(id){
    return Api
        .get(`CADVisualization/scene/custom_scene/?scene_id=${id}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            // return { status: e.response.status, data: e.response.data }
            return e.response.data
        })
}

export function getMoreInspirations(scene_id){
    return Api
        .get(`CADVisualization/scene/inspiration/?scene_id=${scene_id}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function switchSceneImage(body){
    return Api
        .post(
            `CADVisualization/switchData/`,
            body,
            {
                headers:{
                    'X-CSRFToken': getCookie('csrftoken')
                }
            }
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}


