<template>
    <div class="visual-loader">
        <img id="image"  :src=" require('@/assets/' + loaderImage)"/>
        <div class="text">
            <h1 id="change" >
                {{loaderText}}
            </h1>
            <p class="brand-name">Simply Lovable Homes</p>
            <div class="wrapper">
                <div class="ball b1"></div>
                <div class="ball b2"></div>
                <div class="ball b3"></div>
                <div class="ball b4"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VisualLoader',
    data(){
        return{
            loaderImage:'images/Group 1337.png',
            images:[   
                'images/Group 1337.png',             
                'images/Group 1338.png',
                'images/Group 1340.png',
                'images/Group 1342.png',
                'images/Group 1343.png',                
                ],
            loaderText:'Setting up Stuff',
        }
    },

    mounted(){
        const symbols = ['Setting up Stuff','Loading things', 'Refreshing objects']
        let count = 0
        let index= 0
        const element = document.getElementById("change")
        const image_change = document.getElementById("image")
        const iteration = () => {
            if(!element.classList.contains("is-visible") && !image_change.classList.contains("animate-flicker") ){
                element.classList.add("is-visible")
                image_change.classList.add("animate-flicker")            
            
                setTimeout(() => {
                    element.classList.remove("is-visible")
                    image_change.classList.remove("animate-flicker")                  
                    
                }, 1500)
                this.loaderText = symbols[count]
                    this.loaderImage=this.images[index] 
                    count++
                    index++  
                                    
                if (index === this.images.length) {
                    index = 0
                }
                if (count === symbols.length) {
                    count = 0
                }
            }
            
        }
        let inthandle = setInterval(iteration, 2000)
        iteration()
    },
}
</script>

<style lang="scss">
@import './VisualLoader.scss';
</style>
