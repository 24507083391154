<template>
    <div>
        <div v-if="loaderType === 'wave_loader'">
            <WaveLoader/>
        </div>
        <div v-if="loaderType === 'snake_loader'">
            <SnakeLoader/>
        </div>
        <div v-if="loaderType === 'visual_loader'">
            <VisualLoader />
        </div>
    </div>
</template>

<script>
import VisualLoader from 'componentsv2/VisualLoader'
import SnakeLoader from 'componentsv2/SnakeLoader'
import WaveLoader from 'components/WaveLoader'


export default {
    name: 'Loader',
    components: {
        VisualLoader,
        SnakeLoader,
        WaveLoader,
    },
    props: {
        loaderType: {
            type: String,
            default: 'snake_loader',
        },
    },
}
</script>

<style lang="scss">

</style>
