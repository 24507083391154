<template>
    <div class="designer-slot">
        <img :src="img" :alt="name">
        <h1>{{name}}</h1>
    </div>
</template>

<script>
export default {
    name: 'CircularImage',
    props: {
        img: {
            type: String
        },
        name: {
            type: String
        }
    },
}
</script>

<style lang="scss" scoped>
@import './CircularImage';
</style>