import { SNAKE_LOADER } from 'utils/constants'

export default {
    data() {
        return {
            loaderType: SNAKE_LOADER,
            loaderShow: false,
        }
    },
    created() {
        this.loaderShow = true
    },
}
