import { getSceneDetails } from 'api/scenesApi'


const SceneDetailsPage = {
    state: () => ({
        sceneDetails: {},
    }),

    mutations: {
        setSceneDetails(state, payload){
            state.sceneDetails = payload
        },
    },

    getters: {},

    actions: {
        fetchSceneDetails({commit}, id){
            return getSceneDetails(id)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setSceneDetails', payload)
                        return payload
                    }
                })
        },
    }
}

export default SceneDetailsPage;